/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h2: "h2",
    a: "a",
    div: "div",
    p: "p",
    strong: "strong",
    ul: "ul",
    li: "li"
  }, _provideComponents(), props.components), {HrefLangManager} = _components;
  if (!HrefLangManager) _missingMdxReference("HrefLangManager", true);
  return React.createElement(React.Fragment, null, React.createElement(HrefLangManager, {
    alternates: [['x-default', 'https://www.hear.com/news/'], ['en', 'https://www.hear.com/news/'], ['en-US', 'https://www.hear.com/news/'], ['en-CA', 'https://ca.hear.com/news/']]
  }), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "welcome-to-the-hearcom-newsroom",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#welcome-to-the-hearcom-newsroom",
    "aria-label": "welcome to the hearcom newsroom permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Welcome to the hear.com newsroom"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Welcome to hear.com news! We invite you to review our press releases, published articles and press material as needed. Please contact us for any press inquiries."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "press-releases",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#press-releases",
    "aria-label": "press releases permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Press Releases"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.a, {
    href: "/news/press-releases",
    className: "c-md-a"
  }, "Read more")), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "feature-stories",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#feature-stories",
    "aria-label": "feature stories permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Feature Stories"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.strong, null, "Forbes")), "\n", React.createElement(_components.ul, {
    className: "c-md-list"
  }, "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "\n", React.createElement("a", {
    href: "https://www.forbes.com/health/hearing-aids/horizon-hearing-aids-review/",
    target: "_blank"
  }, "Horizon Hearing Aids Review"), "\n"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "\n", React.createElement("a", {
    href: "https://www.forbes.com/sites/forbesbusinesscouncil/2020/10/29/how-masks-are-unmasking-hearing-loss-around-the-globe/?sh=4886702259b4",
    target: "_blank"
  }, "How Masks Are Unmasking Hearing Loss Around The Globe"), "\n"), "\n"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.strong, null, "Forbes Health")), "\n", React.createElement(_components.ul, {
    className: "c-md-list"
  }, "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "\n", React.createElement("a", {
    href: "https://www.forbes.com/health/hearing-aids/how-to-prevent-hearing-loss/",
    target: "_blank"
  }, "Hearing Loss Prevention: Expert Tips For Optimizing Your Hearing Health"), "\n"), "\n"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.strong, null, "New York Times")), "\n", React.createElement(_components.ul, {
    className: "c-md-list"
  }, "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "\n", React.createElement("a", {
    href: "https://www.nytimes.com/wirecutter/reviews/best-over-the-counter-hearing-aids/",
    target: "_blank"
  }, "The Best Over-the-Counter Hearing Aids and Other Hearing Solutions"), "\n"), "\n"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.strong, null, "Business Insider")), "\n", React.createElement(_components.ul, {
    className: "c-md-list"
  }, "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "\n", React.createElement("a", {
    href: "https://www.businessinsider.com/sc/how-smart-technology-in-hearing-aids-can-treat-hearing-loss-2020-9",
    target: "_blank"
  }, "How two entrepreneurs are revolutionizing hearing care with smart technology"), "\n"), "\n"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.strong, null, "Medium.com:")), "\n", React.createElement(_components.ul, {
    className: "c-md-list"
  }, "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "\n", React.createElement("a", {
    href: "https://medium.com/actoncapital/listen-up-how-a-startup-from-berlin-revolutionized-hearing-worldwide-7cdfb6041fe",
    target: "_blank"
  }, "How a Startup from Berlin Revolutionized Hearing Worldwide"), "\n"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "\n", React.createElement("a", {
    href: "https://medium.com/authority-magazine/paul-crusius-of-hear-com-5-things-you-should-do-to-optimize-your-wellness-after-retirement-d5908b81bff0",
    target: "_blank"
  }, "Paul Crusius of Hear.com: 5 Things You Should Do to Optimize Your Wellness After Retirement"), "\n"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "\n", React.createElement("a", {
    href: "https://medium.com/authority-magazine/telehealth-best-practices-dr-hope-lanter-on-how-to-best-care-for-your-patients-when-they-are-not-c98c315375ca",
    target: "_blank"
  }, "Telehealth Best Practices: Dr. Hope Lanter On How To Best Care For Your Patients When They Are Not Physically In Front Of You"), "\n"), "\n"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.strong, null, "Yahoo")), "\n", React.createElement(_components.ul, {
    className: "c-md-list"
  }, "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "\n", React.createElement("a", {
    href: "https://www.yahoo.com/now/not-using-makes-dementia-risk-110214526.html",
    target: "_blank"
  }, "Not Using These Makes Your Dementia Risk Soar, Studies Show"), "\n"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "\n", React.createElement("a", {
    href: "https://ca.style.yahoo.com/easy-habits-avoiding-dementia-brain-125229012.html",
    target: "_blank"
  }, "Easy Habits for Avoiding Dementia, Say Brain Experts"), "\n"), "\n"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.strong, null, "CBS News")), "\n", React.createElement(_components.ul, {
    className: "c-md-list"
  }, "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "\n", React.createElement("a", {
    href: "https://www.cbsnews.com/news/hearing-aid-over-the-counter-cheaper-2022/",
    target: "_blank"
  }, "Cheaper, sleeker over-the-counter hearing aids are on the way"), "\n"), "\n"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.strong, null, "NBC News")), "\n", React.createElement(_components.ul, {
    className: "c-md-list"
  }, "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "\n", React.createElement("a", {
    href: "https://www.nbcnews.com/health/health-news/cheaper-otc-devices-fill-void-left-fda-delay-hearing-aids-n1277294",
    target: "_blank"
  }, "Cheaper OTC devices fill void left by FDA delay on hearing aids"), "\n"), "\n"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.strong, null, "Authority Magazine")), "\n", React.createElement(_components.ul, {
    className: "c-md-list"
  }, "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "\n", React.createElement("a", {
    href: "https://medium.com/authority-magazine/health-tech-paul-crusius-on-how-hears-technology-can-make-an-important-impact-on-our-overall-w-1e4c2f7b515d",
    target: "_blank"
  }, "Health Tech: Paul Crusius On How Hear’s Technology Can Make An Important Impact On Our Overall Wellness"), "\n"), "\n"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.strong, null, "AARP")), "\n", React.createElement(_components.ul, {
    className: "c-md-list"
  }, "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "\n", React.createElement("a", {
    href: "https://www.aarp.org/health/conditions-treatments/info-2022/diabetes-hearing-loss.html",
    target: "_blank"
  }, "The Surprising Link Between Diabetes and Your Ears"), "\n"), "\n"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.strong, null, "USA Today")), "\n", React.createElement(_components.ul, {
    className: "c-md-list"
  }, "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "\n", React.createElement("a", {
    href: "https://www.usatoday.com/story/sponsor-story/hear-com/2022/06/17/changing-face-hearing-care-using-digital-technology/7645814001/",
    target: "_blank"
  }, "Changing the face of hearing care using digital technology"), "\n"), "\n"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.strong, null, "Consumer Affairs")), "\n", React.createElement(_components.ul, {
    className: "c-md-list"
  }, "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "\n", React.createElement("a", {
    href: "https://www.consumeraffairs.com/health/hearing/",
    target: "_blank"
  }, "Best Hearing Aids Brands"), "\n"), "\n"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.strong, null, "Next Avenue")), "\n", React.createElement(_components.ul, {
    className: "c-md-list"
  }, "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "\n", React.createElement("a", {
    href: "https://www.nextavenue.org/determining-the-best-care-for-her-deaf-mother-with-dementia/",
    target: "_blank"
  }, "Finding the Best Care for Her Deaf Mother with Dementia"), "\n"), "\n"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.strong, null, "Slashgear")), "\n", React.createElement(_components.ul, {
    className: "c-md-list"
  }, "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "\n", React.createElement("a", {
    href: "https://www.slashgear.com/hear-horizon-hearing-aids-aim-for-a-younger-active-crowd-16659538",
    target: "_blank"
  }, "Hear.com Horizon Hearing Aids Aim For A Younger, Active Crowd"), "\n"), "\n"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.strong, null, "Seniors Matter")), "\n", React.createElement(_components.ul, {
    className: "c-md-list"
  }, "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "\n", React.createElement("a", {
    href: "https://www.seniorsmatter.com/12-tips-to-save-big-on-hearing-aids/2624447/",
    target: "_blank"
  }, "12 tips to save big on hearing aids"), "\n"), "\n"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.strong, null, "Health Day")), "\n", React.createElement(_components.ul, {
    className: "c-md-list"
  }, "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "\n", React.createElement("a", {
    href: "https://consumer.healthday.com/8-16-fda-eases-access-to-cheaper-over-the-counter-hearing-aids-2657869649.html",
    target: "_blank"
  }, "Cheaper Over-the-Counter Hearing Aids Should Be in Stores by October, FDA Says"), "\n"), "\n"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.strong, null, "Health Digest")), "\n", React.createElement(_components.ul, {
    className: "c-md-list"
  }, "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "\n", React.createElement("a", {
    href: "https://www.healthdigest.com/920450/audiologist-dr-hope-lanter-on-minimizing-risks-of-hearing-damage-during-summer-exclusive-interview/",
    target: "_blank"
  }, "Audiologist Dr. Hope Lanter On Minimizing Risks Of Hearing Damage During Summer - Exclusive Interview"), "\n"), "\n"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.strong, null, "The Hearing Review")), "\n", React.createElement(_components.ul, {
    className: "c-md-list"
  }, "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "\n", React.createElement("a", {
    href: "https://hearingreview.com/hearing-products/hearing-aids/bte/hear-com-launches-horizon-ax-hearing-aid-line",
    target: "_blank"
  }, "Hear.com Launches Horizon AX Hearing Aid Line"), "\n"), "\n"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.strong, null, "Fox 31")), "\n", React.createElement(_components.ul, {
    className: "c-md-list"
  }, "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "\n", React.createElement("a", {
    href: "https://kdvr.com/news/world-hearing-day/",
    target: "_blank"
  }, "World hearing day"), "\n"), "\n"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.strong, null, "HowStuffWorks")), "\n", React.createElement(_components.ul, {
    className: "c-md-list"
  }, "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "\n", React.createElement("a", {
    href: "https://health.howstuffworks.com/human-body/systems/ear/modern-hearing-aids.htm",
    target: "_blank"
  }, "Modern Hearing Aids Do Way More Than Help You Hear"), "\n"), "\n"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.strong, null, "Newsweek")), "\n", React.createElement(_components.ul, {
    className: "c-md-list"
  }, "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "\n", React.createElement("a", {
    href: "https://www.newsweek.com/americas-best-customer-service-2021",
    target: "_blank"
  }, "America’s Best Hearing Companies"), "\n"), "\n"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.strong, null, "ThriveGlobal.com")), "\n", React.createElement(_components.ul, {
    className: "c-md-list"
  }, "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "\n", React.createElement("a", {
    href: "https://thriveglobal.com/stories/paul-crusius-build-a-culture-that-you-yourself-like/",
    target: "_blank"
  }, "Paul Crusius: Build a Culture You Yourself Like"), "\n"), "\n"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.strong, null, "Health Tech Insider")), "\n", React.createElement(_components.ul, {
    className: "c-md-list"
  }, "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "\n", React.createElement("a", {
    href: "https://healthtechinsider.com/2022/05/05/new-line-of-hearing-aids-let-you-test-and-tune-at-home/",
    target: "_blank"
  }, "New Line of Hearing Aids Let You Test and Tune at Home"), "\n"), "\n"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.strong, null, "Construction Equipment")), "\n", React.createElement(_components.ul, {
    className: "c-md-list"
  }, "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "\n", React.createElement("a", {
    href: "https://www.constructionequipment.com/5-ways-prevent-hearing-loss",
    target: "_blank"
  }, "5 Ways to Prevent Hearing Loss"), "\n"), "\n"), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "press-material",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#press-material",
    "aria-label": "press material permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Press Material"), "\n", React.createElement(_components.ul, {
    className: "c-md-list"
  }, "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "\n", React.createElement("a", {
    href: "https://res.cloudinary.com/dhqvlsb3l/image/upload/v1/assets/na/seo-website/hear-com-logo.png",
    target: "_blank"
  }, "High-resolution hear.com logo"), "\n"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, React.createElement("a", {
    href: "https://res.cloudinary.com/dhqvlsb3l/image/upload/v1/assets/na/seo-website/hear-com-logo-transparent.png",
    target: "_blank"
  }, "High-resolution hear.com logo"), ") (transparent)"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "\n", React.createElement("a", {
    href: "https://res.cloudinary.com/dhqvlsb3l/image/upload/v1/assets/na/seo-website/US_Factsheet.pdf",
    target: "_blank"
  }, "Press information kit"), "\n"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "\n", React.createElement("a", {
    href: "https://res.cloudinary.com/dhqvlsb3l/image/upload/v1/assets/na/seo-website/Paul-Crusius-and-Marco-Vietor.jpg",
    target: "_blank"
  }, "Founders and CEO P. Crusius and M. Vietor"), "\n"), "\n"), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "contact-us",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#contact-us",
    "aria-label": "contact us permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Contact Us"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Phone: ", React.createElement(_components.a, {
    href: "tel:8665858861",
    className: "c-md-a"
  }, "(866) 585-8861")), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Email: info@hear.com"));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
